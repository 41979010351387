import moment from "moment";

export default {
    data() {
        return {
            pagination: {
                total: 0, //数据总数
                pageSize: 50, //每页中显示10条数据
                defaultCurrent: 1, //默认当前页面数
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "50", "100"],
                showTotal: (total) => ` ${total} ${this.$t("alarm.a8")}`, //分页中显示总的数据
                showQuickJumper: true, //是否可以快速跳转至某页
                defaultCurrent: 1, //默认当前页面数
                hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
                onShowSizeChange: (current, pageSize) => {
                    this.pagination.defaultCurrent = current;
                    this.pagination.pageSize = pageSize;
                },
                // 改变每页数量时更新显示
                onChange: (current, size) => {
                    this.pagination.defaultCurrent = current;
                    this.pagination.pageSize = size;
                },
            },
        }
    },
    methods: {
        moment,
        // 表格高度计算
        tableSize(value) {
            const indexDom = document.getElementById(value);
            if (indexDom !== null) {
                const height = indexDom.getElementsByClassName("main")[0].clientHeight;
                const title = indexDom.getElementsByClassName("ant-table-thead")[0].clientHeight;
                const tableHeight = height - 118.5 - title;
                this.size.y = tableHeight;
            }
        },
        // 开始日期选择后赋值给结束日期
        dateSelection(value) {
            if (value) {
                const _val = JSON.stringify(value);
                const val = JSON.parse(_val);
                this.form.endValue = moment(val).endOf("day");
            } else {
                this.form.endValue = null
            }
        },
        // 开始日期限制选择范围（未来日期不可选）
        disabledStartDate(startValue) {
            // 获取计算机当前日期
            const nowValue = moment();
            if (!startValue || !nowValue) {
                return false;
            }
            // 禁用大于计算机日期的日期值
            return startValue.valueOf() > nowValue.valueOf();
        },
        // 结束日期限制选择范围（1.未来日期不可选。2.大于开始日期一个月不可选。3.小于开始日期不可选）
        disabledEndDate(endValue) {
            const startValue = this.form.startValue;
            const _value = JSON.stringify(startValue);
            const value = JSON.parse(_value);
            const nowValue = moment().endOf("day");
            if (!endValue || !startValue) {
                return false;
            }
            return (
                startValue.valueOf() > endValue.valueOf() ||
                endValue.valueOf() > nowValue.valueOf() ||
                moment(value).add(1, "months").valueOf() <= endValue.valueOf()
            );
        },
    },
}