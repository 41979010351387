import request from '@/utils/request'
import fileDownload from '@/utils/fileDownload'

// 消费模块--设备页--获取设备列表
export function getEquipmentList(params) {
    return request({
        url: 'bivale/consumeInfo/getConsumeDeviceStatus',
        method: 'get',
        params
    });
}

// 消费模块--设备页--获取详情信息
export function getDetailsInfo(params) {
    return request({
        url: 'bivale/consumeInfo/getSingleDeviceInfo',
        method: 'get',
        params
    });
}

// 消费模块--卡片记录--记录列表查询
export function getRecordList(params) {
    return request({
        url: "bivale/consume/history/getHistoryList",
        method: 'post',
        data: params,
    });
}

// 消费模块--消费设置--消费设置参数获取
export function getConsumeDeviceInfo(params) {
    return request({
        url: 'bivale/consumeInfo/getConsumeDeviceInfo',
        method: 'get',
        params
    });
}

// 消费模块--消费设置--消费参数设置
export function setConsumeDeviceInfo(body, query) {
    return request({
        url: "bivale/consumeInfo/setConsumeDeviceInfo",
        method: 'post',
        data: body,
        params: query,
    });
}

// 消费模块--结算规则设置--获取价格一览表
export function getConsumePosRules(params) {
    return request({
        url: 'bivale/consumeInfo/getConsumePosRules',
        method: 'get',
        params
    });
}

// 消费模块--结算规则设置--餐次价格设置
export function setConsumePosRules(body, query) {
    return request({
        url: "bivale/consumeInfo/setConsumePosRules",
        method: 'post',
        data: body,
        params: query,
    });
}

// 消费模块--每日菜单设置--上传文件
export function uploadMenuFile(params) {
    return request({
        url: "bivale/consumeInfo/uploadMenuFile",
        method: 'post',
        data: params,
    });
}

// 消费模块--消费报表--食堂选择列表
export function getCanteen(params) {
    return request({
        url: 'bivale/consumeInfo/getCanteen',
        method: 'get',
        params
    });
}

// 消费模块--消费报表--消费报表导出
export function report(body, query) {
    return fileDownload({
        url: "bivale/consume/history/report",
        method: 'post',
        responseType: "blob",
        data: body,
        params: query,
    });
}

// 消费模块--站点模块卡片--数据获取
export function displayData(params) {
    return request({
        url: 'bivale/consume/history/getDayHistorySum',
        method: 'get',
        params
    });
}

// 消费模块--数据输入--图表数据获取
export function getViewData(params) {
    return request({
        url: 'bivale/consume/history/getDeviceHistorySum',
        method: 'get',
        params
    });
}

// 分页接口
export function pageList(body) {
    return request({
        url: "/bivale/history/page/list",
        method: 'post',
        data: body
    });
}