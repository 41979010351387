 
 <!--消费管理--数据输出-->
<template>
  <div
    id="dataView"
    v-loading="loading"
    :element-loading-text="$t('consume.a1')"
  >
    <div class="header">
      <my-headertitle>{{ $t("consume.a109") }}</my-headertitle>
      <div class="queryBar">
        <a-row :gutter="16" type="flex" align="bottom">
          <a-col :span="7">
            <my-date>
              <template v-slot:start>
                <a-date-picker
                  v-model="form.startValue"
                  :disabled-date="disabledStartDate"
                  format="YYYY-MM-DD"
                  :placeholder="$t('consume.a35')"
                  :allowClear="false"
                  @change="dateSelection"
                  style="width: 100%"
                />
              </template>
              <template v-slot:end>
                <a-date-picker
                  v-model="form.endValue"
                  :disabled-date="disabledEndDate"
                  format="YYYY-MM-DD"
                  :placeholder="$t('consume.a36')"
                  :allowClear="false"
                  style="width: 100%"
                />
              </template>
            </my-date>
          </a-col>
          <a-col :span="2">
            <a-button type="primary" @click="inquire">
              {{ $t("consume.a37") }}
            </a-button>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="main">
      <div id="my_echarts" :style="size"></div>
    </div>
  </div>
</template>

<script>
import headerTitle from "../../components/Title/headerTitle";
import tabletitle from "../../components/Title/tabletitle";
import dateContainer from "../../components/Title/dateContainer";
import global from "@/mixins/global.js";
import { EleResize } from "../../utils/esresize";
import { getViewData } from "../../api/consume";
import moment from "moment";

export default {
  name: "dataView",
  mixins: [global],
  data() {
    return {
      loading: false,
      form: {
        startValue: moment(),
        endValue: moment(),
      },
      size: { width: "100%", height: "100%" },
      option: {
        title: {
          subtext: "",
          right: "10px",
          subtextStyle: {
            fontSize: 16,
          },
        },
        color: ["#5470c6", "#91cc75", "#fac858", "#ee6666"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          top: "45px",
          left: "30px",
          right: "10px",
          bottom: "20px",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            axisTick: { show: false },
            data: [],
          },
        ],
        yAxis: [
          {
            name: this.$t("consume.a115"),
            type: "value",
          },
        ],
        legend: {
          data: [
            this.$t("consume.a9"),
            this.$t("consume.a10"),
            this.$t("consume.a11"),
            this.$t("consume.a12"),
          ],
        },
        series: [
          {
            name: this.$t("consume.a9"),
            type: "bar",
            barGap: 0,
            emphasis: {
              focus: "series",
            },
            data: [],
          },
          {
            name: this.$t("consume.a10"),
            type: "bar",
            emphasis: {
              focus: "series",
            },
            data: [],
          },
          {
            name: this.$t("consume.a11"),
            type: "bar",
            emphasis: {
              focus: "series",
            },
            data: [],
          },
          {
            name: this.$t("consume.a12"),
            type: "bar",
            emphasis: {
              focus: "series",
            },
            data: [],
          },
        ],
      },
    };
  },
  mounted() {
    // 页面初始化时调用查询接口初始化页面
    this.inquire();
  },
  methods: {
    moment,
    // 查询接口
    inquire() {
      const startValue = this.form.startValue;
      const endValue = this.form.endValue;
      if (startValue == null) {
        this.form.startValue = moment();
        this.form.endValue = moment();
      } else if (endValue == null && startValue !== null) {
        this.form.endValue = this.form.startValue;
      }
      this.loading = true;
      const data = {
        siteId: this.$route.query.id,
        startDate: this.form.startValue.format("YYYY-MM-DD"),
        endDate: this.form.endValue.format("YYYY-MM-DD"),
      };
      getViewData(data)
        .then((res) => {
          this.loading = false;
          const { data } = res;
          console.log(data);
          let name = [];
          let breakfast = [];
          let lunch = [];
          let dinner = [];
          let superDinner = [];
          let count = 0;
          for (let i = 0; i < data.length; i++) {
            name.push(data[i].name);
            breakfast.push(data[i].breakfast);
            lunch.push(data[i].lunch);
            dinner.push(data[i].dinner);
            superDinner.push(data[i].superDinner);
            count = count + data[i].count;
          }
          this.option.title.subtext = `${this.$t("consume.a118") + count}`;
          this.option.xAxis[0].data = name;
          this.option.series[0].data = breakfast;
          this.option.series[1].data = lunch;
          this.option.series[2].data = dinner;
          this.option.series[3].data = superDinner;
          this.resize();
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    // 图表渲染就自适应的方法
    resize() {
      let myChart = this.$echarts.init(document.getElementById("my_echarts"));
      let resizeDiv = document.getElementById("my_echarts");
      myChart.setOption(this.option);
      let listener = () => {
        myChart.resize();
      };
      EleResize.on(resizeDiv, listener);
    },
  },
  components: {
    "my-headertitle": headerTitle,
    "my-tabletitle": tabletitle,
    "my-date": dateContainer,
  },
};
</script>

<style scoped>
#dataView {
  width: 100%;
  height: 100%;
}
.header {
  height: 140px;
  padding: 0px 20px;
}
.queryBar {
  padding: 10px;
  background-color: aliceblue;
  border-bottom: 1px solid rgba(12, 12, 12, 0.1);
}
.main {
  height: calc(100% - 140px);
  padding: 0px 20px;
}
</style>