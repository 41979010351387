<!--页面标题组件-->
<template>
  <div id="headertitle">
    <div class="headertitle-left">
      <slot></slot>
    </div>
    <div class="headertitle-right">
      <div class="headertitle-right-name">
        <slot name="name"></slot>
      </div>
      <div v-show="quit">
        <a-icon
          type="close"
          @click="returnTo"
          :style="{ fontSize: '20px', color: '#7682CE' }"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    quit: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    returnTo() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
#headertitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
  white-space: nowrap;
  border-bottom: 1px solid rgba(12, 12, 12, 0.1);
}
.headertitle-left {
  display: flex;
  align-items: center;
  letter-spacing: 4px;
  font-size: 20px;
}
.headertitle-right {
  display: flex;
  align-items: center;
}
.headertitle-right-name {
  display: flex;
  align-items: center;
  font-size: 15px;
}
.headertitle-left::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 30px;
  background-color: #7682ce;
  margin-right: 15px;
}
</style>