<!-- 表格标题组件 -->
<template>
  <div id="tabletitle">
    <slot></slot>
    <span>{{title}}</span>
    <div class="headertitle-right">
      <div class="headertitle-right-name">
        <slot name="name"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props:['title']
        
};
</script>

<style scoped>
#tabletitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 52.5px;
  background: #f3f3f5;
  font-size: 15px;
  padding: 0px 30px;
  white-space: nowrap;
  overflow: hidden;
}
.headertitle-right {
  display: flex;
  align-items: center;
}
.headertitle-right-name {
  display: flex;
  align-items: center;
  font-size: 15px;
}
</style>