<!--开始日期和结束日期容器-->
<template>
  <div id="dateContainer">
    <div class="date">
      <slot name="start"></slot>
    </div>
    <div class="to">
      <span>~</span>
    </div>
    <div class="date">
      <slot name="end"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name:'dateContainer'
};
</script>

<style scoped>
#dateContainer {
  display: flex;
  align-items: center;
  width: 100%;
}
.date {
  width: 50%;
}
.to {
  font-size: 18px;
  margin: 0px 5px;
}
div /deep/.ant-calendar-picker{
  min-width: 10px !important;
}
</style>